import './App.css';

import React, {useEffect} from 'react'

function App() {
  useEffect(() => {
    window.location.assign('https://share.garmin.com/T8GF9')
  })

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Where is Hugh? Showing you soon.
        </p>
      </header>
    </div>
  );
}

export default App;
